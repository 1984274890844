
























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'UCIrvineChem1LDLabReport4Argumentation',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        claimAnswer: null,
        argumentationAnswer: null,
      },
      questions: [
        {
          text: 'In the space below, please write your claim.',
          inputName: 'claimAnswer',
          maxInputLength: 250,
        },
        {
          text: 'In the space below, please write your remaining argumentation.',
          inputName: 'argumentationAnswer',
          maxInputLength: 2750,
        },
      ],
    };
  },
});
